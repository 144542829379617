import clsx from 'clsx'
import { useCallback, useContext, useState } from 'react'
import { NotificationContext } from '../../providers/NotificationContextProvider'
import sharedStyles from './shared.module.css'
import SocialLinks from './SocialLinks'
import styles from './UserMenuPopover.module.css'

export function toInitials(name) {
  if (!name) {
    return ''
  }
  const usersInitials = name
    .split(' ')
    .map((s) => {
      return s[0]
    })
    .join('')
  return usersInitials
}

function ItemGroup({ children }) {
  return <li className={styles.dropdownItemGroup}>{children}</li>
}

function TitleItem({ children, pro }) {
  return (
    <span className={clsx(styles.dropdownTitle, styles.dropdownTitleStatus)}>
      {children}
      {pro ? (
        <span className={clsx(styles.dropdownTitleStatusItem, styles.dropdownTitleStatusPro)}>
          pro
        </span>
      ) : (
        <span className={clsx(styles.dropdownTitleStatusItem, styles.dropdownTitleStatusFree)}>
          free
        </span>
      )}
    </span>
  )
}

function LinkItem({ children, href, onClick }) {
  return (
    <a className={clsx(styles.dropdownItem, styles.dropdownLink)} href={href}>
      {children}
    </a>
  )
}
function InfoItem({ initials, name, subtitles, href, avatarImgSrc }) {
  return (
    <a className={clsx(styles.dropdownUser, styles.dropdownItem)} href={href}>
      <div className={styles.dropdownUserAvatar}>
        {avatarImgSrc ? <img src={avatarImgSrc} /> : <span>{initials}</span>}
      </div>

      <div className={styles.dropdownUserInfo}>
        <span className={styles.dropdownUserName}>{name}</span>
        {subtitles
          .filter((s) => !!s)
          .map((subtitle) => (
            <span className={styles.dropdownUserSubtitle}>{subtitle}</span>
          ))}
      </div>
    </a>
  )
}
function LabelItem({ children, href, notificationCount }) {
  return (
    <LinkItem href={href}>
      <span className={styles.dropdownLabel}>
        <span className={styles.dropdownLabelText}>{children}</span>
        {notificationCount < 0 &&
          (notificationCount < 10 ? (
            <span className={styles.dropdownLinkBadge}>{notificationCount}</span>
          ) : (
            <span className={clsx(styles.dropdownLinkBadge, styles.dropdownLinkBadgePlus)}>9+</span>
          ))}
      </span>
    </LinkItem>
  )
}

function SubNavItem({ onClick, children, items }) {
  const handleClick = (event) => {
    event.stopPropagation()
    onClick()
  }
  return (
    <a className={clsx(styles.dropdownItem, styles.dropdownLink)} onClick={handleClick}>
      <span className={styles.dropdownLabelText}>{children}</span>
      <span className={styles.dropdownLinkSubMenu}>
        {items && <span className={styles.dropdownLinkSubMenuBadge}>{items}</span>}
        <span
          className={clsx(styles.dropdownLinkSubMenuIcon, sharedStyles.chevronBlackIcon)}
        ></span>
      </span>
    </a>
  )
}
function LoginItemGroup() {
  return (
    <ItemGroup>
      <LabelItem href="/account/">Log in</LabelItem>
      <LabelItem href="/register/">Sign Up</LabelItem>
    </ItemGroup>
  )
}
function MembershipCallToAction() {
  return (
    <a className={clsx(styles.dropdownCtaLink, styles.dropdownItem)} href="/about/services/">
      Upgrade to
      <span className={styles.proBadge}>pro</span>
    </a>
  )
}

function BackLinkItem({ onClick }) {
  const handleClick = (event) => {
    event.stopPropagation()
    onClick()
  }
  return (
    <ItemGroup>
      <a className={clsx(styles.dropdownItem, styles.dropdownLink)} onClick={handleClick}>
        <span className={styles.dropdownLabel}>
          <span className={clsx(styles.dropdownLinkBackIcon, sharedStyles.chevronBlackIcon)}></span>
          <span className={styles.dropdownLabelText}>Back</span>
        </span>
      </a>
    </ItemGroup>
  )
}
function SubNavTitle({ children }) {
  return <span className={styles.dropdownTitle}>{children}</span>
}
export default function UserMenuPopover({ open, userSettings }) {
  const { alerts, announcements } = useContext(NotificationContext)
  const [activeSubNav, setActiveSubNav] = useState({})
  const toggleSubNav = useCallback(
    (subNavName) => {
      setActiveSubNav((prev) => (prev[subNavName] === true ? {} : { [subNavName]: true }))
    },
    [setActiveSubNav]
  )
  const subNavOpen = Object.values(activeSubNav).some((v) => v)
  const notificationCount = alerts?.length + announcements?.length
  const isAgencyUser = userSettings?.agencyAssociations?.length > 0
  const canUpload = userSettings?.showUploader

  return (
    <div className={clsx(styles.popover, { [styles.popoverOpen]: open })}>
      <ul className={styles.dropdown}>
        {!subNavOpen && (
          <>
            {userSettings?.authenticated ? (
              <>
                <ItemGroup>
                  <TitleItem pro={userSettings?.proSearchPermission == 'proSearch'}>
                    {userSettings?.email}
                  </TitleItem>
                </ItemGroup>
                {userSettings?.agencyAssociations?.length > 0 && (
                  <ItemGroup>
                    {userSettings?.agencyAssociations?.slice(0, 1).map((agency) => (
                      <InfoItem
                        key={agency.agencyPostslug}
                        initials={toInitials(agency.agencyName)}
                        name={agency.agencyName}
                        subtitles={[agency.agencyTypeName, agency.location]}
                        avatarImgSrc={agency.agencyPic}
                        href={`/agency/${agency.agencyPostslug}`}
                      />
                    ))}
                    {isAgencyUser && userSettings?.agencyAssociations?.length === 1 && (
                      <>
                        <LabelItem href="/account/agency-listing/talent/">Manage People</LabelItem>
                        <LabelItem href="/account/agency-listing/insights.cfm">
                          Agency Insights
                        </LabelItem>
                        <LabelItem href="/account/agency-listing/">Agency Settings</LabelItem>
                      </>
                    )}
                  </ItemGroup>
                )}
                {userSettings?.agencyAssociations?.length > 1 && (
                  <>
                    <ItemGroup>
                      <SubNavItem
                        open={activeSubNav['agencies']}
                        items={userSettings?.agencyAssociations?.length}
                        onClick={() => toggleSubNav('agencies')}
                      >
                        See All Agency Profiles
                      </SubNavItem>
                    </ItemGroup>
                    <ItemGroup>
                      <LabelItem href="/account/agency-listing/talent/">Manage People</LabelItem>
                      <LabelItem href="/account/agency-listing/insights.cfm">
                        Agency Insights
                      </LabelItem>
                      <LabelItem href="/account/agency-listing/">Agency Settings</LabelItem>
                    </ItemGroup>
                  </>
                )}

                <ItemGroup>
                  {userSettings?.userLink && (
                    <InfoItem
                      key={userSettings?.personPostSlug}
                      avatarImgSrc={userSettings?.userPicture}
                      initials={toInitials(userSettings?.name)}
                      name={userSettings?.name}
                      subtitles={[userSettings?.userType]}
                      href={userSettings?.userLink}
                    />
                  )}
                  {userSettings?.userLink && (
                    <LabelItem href="/account/profile/insights/">Your Page Insights</LabelItem>
                  )}
                  {canUpload && <LabelItem href="/account/profile/editor/">Upload Work</LabelItem>}
                  <LabelItem
                    href="/account/notifications/account-notifications/"
                    notificationCount={notificationCount}
                  >
                    Notifications
                  </LabelItem>
                  <LabelItem href="/lists/">Your Lists</LabelItem>
                  <LabelItem href="/account/settings/edit_info.cfm">Settings</LabelItem>
                </ItemGroup>
              </>
            ) : (
              <>
                <LoginItemGroup />
                <ItemGroup>
                  <MembershipCallToAction />
                </ItemGroup>
              </>
            )}
            <ItemGroup>
              <SubNavItem onClick={() => toggleSubNav('help')}>Help &amp; Support</SubNavItem>
            </ItemGroup>
            {userSettings?.authenticated && (
              <ItemGroup>
                <LabelItem href="/account/?logout">Log out</LabelItem>
              </ItemGroup>
            )}
          </>
        )}
        {subNavOpen && <BackLinkItem onClick={() => setActiveSubNav({})} />}
        {activeSubNav['help'] && (
          <>
            <ItemGroup>
              <SubNavTitle>Help &amp; Support</SubNavTitle>
              <LinkItem href="/help/">Help &amp; FAQ</LinkItem>
              <LinkItem href="/feedback/feedback.html">Contact Us</LinkItem>
              <LinkItem href="/about/services/">About Models.com</LinkItem>
            </ItemGroup>
            <ItemGroup>
              <SocialLinks className={styles.dropdownItem} />
            </ItemGroup>
          </>
        )}
        {activeSubNav['agencies'] && (
          <ItemGroup>
            <SubNavTitle>All Agency Profiles</SubNavTitle>
            {userSettings?.agencyAssociations?.map((agency) => (
              <InfoItem
                key={agency.agencyPostslug}
                initials={toInitials(agency.agencyName)}
                name={agency.agencyName}
                subtitles={[agency.agencyTypeName, agency.location]}
                avatarImgSrc={agency.agencyPic}
                href={`/agency/${agency.agencyPostslug}`}
              />
            ))}
          </ItemGroup>
        )}
      </ul>
    </div>
  )
}
