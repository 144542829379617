const isProd = !['localhost', 'beta.models.com'].includes(window.location.hostname)
const HOSTNAME = isProd ? 'https://api.models.com' : 'https://beta-api.models.com'
const API_PATH = `${HOSTNAME}/prosearch/findPeople.html`
const IMAGE_UPLOAD_HOSTNAME = isProd ? 'https://models.com' : 'https://beta.models.com'
const IMAGE_UPLOAD_API_PATH = `${IMAGE_UPLOAD_HOSTNAME}/account/agency-listing/edit/imageUpload.html`

export const API_SERVER_NAME = HOSTNAME
export const API_URL = API_PATH

// reducer actions
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const ERROR = 'error'

function urlWithNotificationReset(request) {
  if (request.hasOwnProperty('Notifications')) {
    return `${API_PATH}?mdcdbapi=true&Notifications=1`
  } else {
    sessionStorage.setItem('notificationLP', 0)
    return `${API_PATH}?mdcdbapi=true`
  }
}

export async function fetchData(request) {
  const options = {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  }
  const responseRaw = await fetch(urlWithNotificationReset(request), options)
  if (!responseRaw.ok) {
    throw `Request returned unsuccessfully. Status Code:  ${responseRaw.status}`
  }
  return await responseRaw.json()
}

export async function uploadImage(request) {
  const options = {
    credentials: 'include',
    method: 'POST',
    body: request,
  }
  const responseRaw = await fetch(IMAGE_UPLOAD_API_PATH, options)
  if (!responseRaw.ok) {
    throw `Request returned unsuccessfully. Status Code:  ${responseRaw.status}`
  }
  return await responseRaw.json()
}

async function withDispatch(fn, dispatch) {
  try {
    dispatch({ type: LOADING })
    const response = await fn()
    if (response && response.success) {
      dispatch({ type: SUCCESS, ...response })
    } else {
      dispatch({ type: ERROR, ...response })
      return false
    }
    return true
  } catch (err) {
    dispatch({ type: ERROR })
  }
  return false
}

export async function fetchDataWithDispatch(request, dispatch) {
  return await withDispatch(async () => fetchData(request), dispatch)
}

export async function uploadImageWithDispatch(request, dispatch) {
  return await withDispatch(async () => uploadImage(request), dispatch)
}
