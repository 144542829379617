const pathStyle = {
  fill: '#231f20',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
}
export default function Logo() {
  return (
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 458.10666 101.24"
      id="svg2"
      version="1.1"
    >
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,101.24)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            style={{ pathStyle }}
            d="M 787.18,16.543 H 644.613 V 349.18 c 0,35.785 -8.961,64.05 -26.855,84.711 -15.156,18.589 -34.442,27.894 -57.856,27.894 -24.785,0 -46.148,-10 -64.043,-29.969 C 476.566,409.773 466.93,380.844 466.93,345.027 V 16.543 H 321.27 v 330.562 c 0,37.196 -4.477,63.704 -13.426,79.555 -13.082,21.324 -37.547,32.012 -73.336,32.012 -59.238,0 -88.848,-35.817 -88.848,-107.422 V 16.543 H 0 v 550.605 h 137.402 v -52.691 c 0,-19.992 0.332,-29.965 1.028,-29.965 0,0.692 0.34,1.383 1.035,2.074 21.336,33.051 43.035,56.825 65.074,71.278 24.797,15.828 55.789,23.765 92.977,23.765 37.195,0 66.976,-6.726 89.363,-20.152 22.363,-13.43 43.543,-36.336 63.527,-68.691 19.965,29.586 41.481,52.168 64.559,67.664 23.066,15.492 51.144,23.242 84.195,23.242 35.125,0 68.695,-8.961 100.723,-26.856 32.027,-17.921 54.574,-41.164 67.656,-69.746 13.086,-28.586 19.641,-58.363 19.641,-89.34 V 16.543"
          />
          <path
            id="path16"
            style={{ pathStyle }}
            d="m 1239.63,291.316 c 0,111.575 -44.08,167.356 -132.23,167.356 -88.84,0 -133.259,-55.781 -133.259,-167.356 0,-111.57 44.419,-167.351 133.259,-167.351 88.15,0 132.23,55.781 132.23,167.351 z m 147.71,0 c 0,-87.461 -25.13,-157.515 -75.4,-210.2144 C 1261.66,28.4297 1193.49,2.07031 1107.4,2.07031 1019.93,2.07031 950.902,28.7695 900.277,82.1406 849.664,135.5 824.352,205.223 824.352,291.316 c 0,86.789 25.14,156.852 75.406,210.223 50.265,53.363 119.482,80.07 207.642,80.07 88.15,0 157.36,-27.214 207.64,-81.613 48.19,-53.047 72.3,-122.578 72.3,-208.68"
          />
          <path
            id="path18"
            style={{ pathStyle }}
            d="m 1809.86,278.926 c 0,118.457 -40.97,177.676 -122.93,177.676 -82.63,0 -123.95,-56.481 -123.95,-169.407 0,-53.047 12.73,-95.027 38.22,-126.035 22.72,-27.547 50.96,-41.316 84.7,-41.316 33.74,0 61.98,13.426 84.72,40.285 26.16,29.617 39.24,69.207 39.24,118.797 z M 1949.33,16.543 H 1807.8 v 68.1758 h -2.05 C 1771.31,28.2422 1720.69,0 1653.9,0 c -46.85,0 -89.91,13.6133 -129.15,40.8086 -39.26,27.207 -67.49,62.1524 -84.71,104.8434 -17.22,42.707 -25.82,92.989 -25.82,150.852 0,78.492 17.89,143.586 53.71,195.219 40.62,59.922 98.82,89.886 174.59,89.886 67.48,0 120.51,-28.929 159.08,-86.773 h 2.06 v 264.449 h 145.67 V 16.543"
          />
          <path
            id="path20"
            style={{ pathStyle }}
            d="m 2358.38,345.027 c -3.46,36.883 -16.01,65.891 -37.71,87.059 -21.69,21.168 -46.66,31.746 -74.89,31.746 -27.56,0 -51.31,-9.891 -71.28,-29.699 -22.04,-21.164 -35.82,-50.867 -41.32,-89.106 z m 148.76,-91.922 h -379.12 c 3.43,-45.468 17.9,-80.593 43.39,-105.371 22.04,-20.664 48.89,-30.976 80.57,-30.976 23.4,0 44.42,5.5 63.02,16.512 18.58,11.007 30.63,25.824 36.15,44.425 H 2499.9 C 2483.38,121.914 2453.76,78.6836 2411.06,48.0469 2368.37,17.3906 2317.73,2.07031 2259.22,2.07031 c -183.89,0 -275.82,100.22669 -275.82,300.62469 0,84.692 23.24,152.364 69.73,202.989 46.48,50.617 111.71,75.925 195.76,75.925 172.15,0 258.25,-109.511 258.25,-328.504"
          />
          <path
            id="path22"
            style={{ pathStyle }}
            d="m 2694.12,16.543 h -145.66 v 742.742 h 145.66 V 16.543"
          />
          <path
            id="path24"
            style={{ pathStyle }}
            d="m 3241.61,192.168 c 0,-39.277 -9.65,-73.367 -28.92,-102.293 C 3171.37,29.9727 3099.73,0 2997.81,0 c -69.59,0 -129.13,15.8672 -178.72,47.5391 -55.11,35.789 -82.99,84.0039 -83.67,144.6289 h 149.79 c 0,-26.887 11.69,-47.891 35.12,-63.02 20.66,-13.113 46.13,-19.628 76.45,-19.628 26.85,0 50.1,5.324 69.71,16.007 19.65,10.664 29.47,24.602 29.47,41.825 0,22.738 -29.31,40.625 -87.83,53.711 -86.76,19.972 -141.51,35.468 -164.24,46.503 -58.55,28.227 -87.81,71.614 -87.81,130.164 0,34.434 8.26,65.754 24.79,93.993 16.53,28.238 44.42,50.789 83.67,67.672 39.27,16.875 81.61,25.312 127.08,25.312 61.97,0 113.96,-14.824 155.97,-44.43 48.21,-33.05 74.03,-79.902 77.49,-140.472 h -143.59 c -7.6,48.871 -39.97,73.336 -97.11,73.336 -23.42,0 -43.74,-5.164 -60.94,-15.504 -17.23,-10.317 -25.83,-23.426 -25.83,-39.246 0,-20.661 29.61,-37.547 88.83,-50.625 86.09,-19.282 141.53,-34.789 166.31,-46.489 59.24,-28.925 88.86,-71.968 88.86,-129.109"
          />
          <path
            id="path26"
            style={{ pathStyle }}
            d="m 3435.82,18.6133 h -156 V 169.434 h 156 V 18.6133"
          />
        </g>
      </g>
    </svg>
  )
}
